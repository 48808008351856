import axios from "axios";
import { API_PAYMENT_URL } from "../constants";

export const prepareAndPay = ({
  payload,
  setMessage,
  limitMaxPaymentEuPlatesc,
  user_id,
}) => {
  const payments = payload.map((item) => ({
    name: item.tip_plata,
    payload: {
      amount: parseFloat(item.suma),
      uniq: item.numar_fond,
      currency: "RON",
    },
  }));

  const sum = payload.reduce((sum, obj) => parseFloat(obj.suma) + sum, 0);

  const newPayload = {
    to: payload[0].numar_fond.slice(0, 7),
    payments: payments,
    successUrl: `${window.location.origin}/confirmare-success-plata?`,
    failedUrl: `${window.location.origin}/confirmare-eroare-plata?`,
    backToSite: `${window.location.origin}`,
    source: "membri.carmetropolitan.ro",
    payedByUserByUniq: user_id,
  };

  if (limitMaxPaymentEuPlatesc && sum > limitMaxPaymentEuPlatesc) {
    newPayload.options = {
      service: "smartpay",
    };
    newPayload.redirectURL = `${window.location.origin}/confirmare?paymentId=:paymentId`;
  }

  axios
    .post(`${API_PAYMENT_URL}/v2/payments`, { ...newPayload })
    .then((data) => {
      const { urlToPayment = "" } = data?.data?.data;

      if (!urlToPayment) {
        return setMessage(
          "Probleme cu generarea platii, va rugam reveniti mai tarziu."
        );
      }

      return window.location.replace(urlToPayment);
    })
    .catch((err) => {
      setMessage(
        err?.response?.data?.message ||
          "A survenit o eroare, va rugam sa ne contactati!"
      );
    });

  return false;
};

export const prepareyAndPayForGuarnator = ({
  payments,
  setMessage,
  user_id,
}) => {
  const newPayload = {
    to: payments[0].payload.uniq.slice(0, 7),
    payments: payments,
    successUrl: `${window.location.origin}/confirmare-success-plata?`,
    failedUrl: `${window.location.origin}/confirmare-eroare-plata?`,
    backToSite: `${window.location.origin}`,
    source: "membri.carmetropolitan.ro/giranti",
    payedByUserByUniq: user_id,
  };

  axios
    .post(`${API_PAYMENT_URL}/payments`, { ...newPayload })
    .then((data) => {
      const { urlToPayment = "" } = data?.data?.data;

      if (!urlToPayment) {
        return setMessage(
          "Probleme cu generarea platii, va rugam reveniti mai tarziu."
        );
      }

      return window.location.replace(urlToPayment);
    })
    .catch((err) => {
      setMessage(
        err?.response?.data?.message ||
          "A survenit o eroare, va rugam sa ne contactati!"
      );
    });

  return false;
};

export const getLimitEuPlatesc = async () => {
  const limit = 0;
  try {
    const response = await axios.get(
      `${API_PAYMENT_URL}/euplatesc/maximum-payment-limit`
    );

    if (response.data.limit) {
      return parseFloat(response.data.limit);
    }
  } catch (err) {}

  return limit;
};

export const checkStatusPaymentOfSmartPay = async (paymentId) => {
  try {
    const response = await axios.get(
      `${API_PAYMENT_URL}/smartpay/status/${paymentId}`
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
