import React from "react";

const EditWhatIWillPay = ({ list, setListWhatWillPay }) => {
  const change = (e) => {
    let newValue = e.target.value.replace(",", ".");
    var regex = /^[0-9.]+$/;
    if (!newValue.match(regex)) {
      alert("Introduceti doar numeric");
      return false;
    }

    const sumaNoua = newValue;

    setListWhatWillPay((prev) =>
      prev.map((p) =>
        p.numar_fond === list.numar_fond ? { ...p, sumaNoua } : p
      )
    );
  };

  const save = () => {
    setListWhatWillPay((prev) =>
      prev.map((p) =>
        p.numar_fond === list.numar_fond
          ? { ...p, suma: p.sumaNoua, edit: false }
          : p
      )
    );
  };

  return (
    <div className="inline-block">
      <p className="lead inline-block"> {list.nume_tip}: &nbsp;&nbsp;&nbsp;</p>
      <input
        onKeyDown={({ key }) => (key === "Enter" ? save() : null)}
        type="text"
        name={list.nume_tip}
        value={list.sumaNoua || Math.floor(list.suma)}
        className="col-md-2 col"
        placeholder="RON"
        onChange={change}
      />
      <button
        type="button"
        className={"my-2 px-4 btn color--black btn btn--sm type--uppercase"}
        onClick={save}
      >
        Salveaza
      </button>
    </div>
  );
};

export default EditWhatIWillPay;
